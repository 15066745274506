<template>
  <ion-list v-if="cartModule.cartItems.length" lines="full" class="spaced bordered cart-product-list">
    <ion-item-sliding v-for="cartItem in cartModule.cartItems" :key="cartItem.hash">
      <ion-item @click="openCartItemPropertiesModal(cartItem.id, cartItem)">
        <ion-note slot="start" class="quantity" color="black">{{ cartItem.quantity }}x</ion-note>
        <ion-label class='ion-text-wrap'>
          <h2>{{ cartItem.meta.name }}</h2>
          <div class="product-addons" v-if="cartItem.addons && cartItem.addons.length">
            <template v-for="addon in cartItem.addons" :key="addon.id">
              <p v-if="!isDefaultOption(addon.label, addon.selected)">
                <strong v-if="false">{{ addon.label }}: </strong>
                <span v-if="addon.type === 'radio'">{{ addon.selected }}</span>
                <span v-if="addon.type === 'checkbox'">{{ capitalizeCheckboxOptionList(addon.options) }}</span>
              </p>
            </template>
          </div>
        </ion-label>
        <ion-note slot="end" class='ion-text-wrap'>{{ $currency(cartItem.price) }}</ion-note>
      </ion-item>
      <ion-item-options side="end">
        <ion-item-option @click="cartModule.deleteVariation(cartItem.hash)">Quitar</ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
  <ion-list lines="none" class="cart-totals-list border-bottom-thin">
    <ion-item>
      <ion-label>Subtotal</ion-label>
      <ion-note slot="end">{{ $currency(cartModule.orderTotals.subtotal) }}</ion-note>
    </ion-item>
    <ion-item v-if="cartModule.orderTotals.shipping">
      <ion-label class="total-title">Envío</ion-label>
      <ion-note slot="end">{{ $currency(cartModule.orderTotals.shipping) }}</ion-note>
    </ion-item>
    <ion-item v-if="cartModule.orderTotals.discounts">
      <ion-label class="total-title">Descuentos</ion-label>
      <ion-note slot="end">-{{ $currency(cartModule.orderTotals.discounts) }}</ion-note>
    </ion-item>
    <ion-item>
      <ion-label class="total-title"><strong>Total</strong> <small style="color: #8c8c8c">IVA Incluído</small>
      </ion-label>
      <ion-note slot="end">{{ $currency(cartModule.orderTotals.total) }}</ion-note>
    </ion-item>
  </ion-list>
</template>

<script lang="ts">
import {
  IonLabel,
  IonList,
  IonItem,
  IonNote,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  modalController
} from '@ionic/vue';
import { defineComponent } from "vue";
import CartProductModal from "@/components/Modals/CartProductModal.vue";
import cartModule from '@/store/modules/cart';

export default defineComponent({
  name: 'CartAddressComponent',
  components: {IonLabel, IonList, IonItem, IonNote, IonItemSliding, IonItemOptions, IonItemOption},
  methods: {
    isDefaultOption(option: string, selected: string) {
      return option === 'Tipo de carne' && selected === 'Carne de ternera' ||
        option === 'Salsa' && selected === 'Con mayonesa';
    },
    capitalizeCheckboxOptionList(list: string[]) {
      const filteredList = list.filter((o: any) => o.selected).map((o: any) => o.label)
      const joinedList = filteredList.join(', ').toLowerCase();
      return joinedList.charAt(0).toUpperCase() + joinedList.slice(1);
    },
    async openCartItemPropertiesModal(itemId: string, cartItem: any) {
      const modal = await modalController
        .create({
          component: CartProductModal,
          cssClass: 'adaptable-height-modal cart-product-modal',
          componentProps: {cartItem},
        })
      return modal.present();
    },
  },

  setup() {
    return {
      cartModule,
    };
  }
})
</script>

<style lang="scss" scoped>

ion-list {
  &.cart-product-list {
    ion-item {
      ion-note {
        font-size: 0.8em;
      }

      .quantity {
        background: var(--ion-color-light);
        padding: 3px;
        border-radius: 3px;
      }

      ion-label h2 {
        font-size: 1em;
      }

      .product-addons p {
        font-size: 0.8em;
        color: var(--ion-color-primary);
        opacity: 0.7;
      }
    }
  }

  &.cart-totals-list {
    margin-bottom: var(--ion-margin, 16px);
    padding-top: 5px;

    ion-item {
      --min-height: 30px;

      &:last-child {
        padding-bottom: 5px;
      }
    }

    ion-label, ion-note {
      font-size: 0.8em;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
</style>
