<template>
  <div class="inner-content ion-text-center text-dark">
    <div class="ion-padding">
      <p>
        <span style="font-size: 1.1em; display: block; font-weight: bold;">{{ cartItem.meta.name }}</span>
        <small class="text-medium">{{ $currency(cartItem.price) }}</small>
      </p>
      <small v-html="cartItem.meta.description"></small>
    </div>
    <hr>
    <div class="ion-padding-vertical">
      <ion-button @click="debouncedDecreaseItemQty" :disabled="!itemQty" fill="clear" class="qty-button">
        <ion-icon :icon="removeOutline" size="large" color="secondary"/>
      </ion-button>
      <ion-text class="qty-indicator">{{ itemQty }}</ion-text>
      <ion-button @click="debouncedIncreaseItemQty" fill="clear" class="qty-button">
        <ion-icon :icon="addOutline" size="large" color="secondary"/>
      </ion-button>
    </div>
    <ion-button class="ion-margin-horizontal" expand="full" color="secondary" v-if="!itemQty"
                @click="removeVariationFromCart">Eliminar producto
    </ion-button>
    <ion-button class="ion-margin-horizontal" expand="full" color="secondary" v-else
                @click="updateVariationQuantity(itemQty)">Continuar
    </ion-button>
  </div>
</template>

<script>
import { IonIcon, IonText, IonButton, modalController } from '@ionic/vue';
import { removeOutline, addOutline } from 'ionicons/icons'
import cartModule from '@/store/modules/cart';
import { defineComponent, ref } from "vue";
import debounce from "lodash/debounce";

export default defineComponent({
  name: 'CartProductModal',
  components: {IonIcon, IonText, IonButton},
  props: ['cartItem'],
  created() {
    this.debouncedIncreaseItemQty = debounce(this.increaseItemQty, 100)
    this.debouncedDecreaseItemQty = debounce(this.decreaseItemQty, 100)
  },
  setup(props) {

    const itemQty = ref(props.cartItem.quantity);

    function increaseItemQty() {
      itemQty.value++;
    }

    function decreaseItemQty() {
      itemQty.value--;
    }

    function removeVariationFromCart() {
      cartModule.deleteVariation(props.cartItem.hash);
      modalController.dismiss();
    }

    function updateVariationQuantity(qty) {
      cartModule.setVariationQuantity({hash: props.cartItem.hash, qty});
      modalController.dismiss();
    }

    return {
      removeVariationFromCart,
      updateVariationQuantity,
      increaseItemQty,
      decreaseItemQty,
      itemQty,
      removeOutline,
      addOutline
    };
  }
})
</script>

<style lang="scss">
.cart-product-modal {
  hr {
    background-color: var(--ion-color-light);
  }

  .qty-button {
    display: inline-block;
    vertical-align: middle;
    margin: 0 16px;
  }

  .qty-indicator {
    // TODO: [UI] (rdelcampo) Make this indicator fixed-width to prevent resizing on double numbers
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    padding: 2px 10px;
    box-shadow: 0 1px 5px -1px var(--ion-color-medium);
    border-radius: 20px;
  }

}
</style>
