<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="hideModal">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Datos del pedido</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <form id="form" @submit.prevent="submitAddressForm">
      <ion-list lines="full">
        <ion-list-header>
          <ion-label>Datos de entrega</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label position="floating">Nombre</ion-label>
          <ion-input name="first_name" v-model="deliveryShippingData.first_name" type="text"
                     autocomplete="given-name"
                     inputmode="inputmode" :required="true"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Apellidos</ion-label>
          <ion-input name="last_name" v-model="deliveryShippingData.last_name" type="text"
                     autocomplete="family-name"
                     inputmode="inputmode" :required="true"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Teléfono</ion-label>
          <ion-input name="phone" v-model="deliveryShippingData.phone" type="tel" autocomplete="phone"
                     inputmode="inputmode" :required="true" pattern="[0-9]{9}"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input name="email" v-model="deliveryShippingData.email" type="email" autocomplete="email"
                     inputmode="inputmode" :required="true"></ion-input>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">Dirección</ion-label>
          <ion-input name="address_1" v-model="deliveryShippingData.address_1" type="text"
                     autocomplete="address-line1"
                     placeholder="Dirección - Línea 1" :required="true"></ion-input>
          <ion-input name="address_2" v-model="deliveryShippingData.address_2" type="text"
                     autocomplete="address-line2"
                     placeholder="Dirección - Línea 2"></ion-input>
          <ion-input name="city" v-model="deliveryShippingData.city" type="text" autocomplete=""
                     placeholder="Población" :required="true"></ion-input>
          <ion-input name="postcode" v-model="deliveryShippingData.postcode" pattern="[0-9]{5}" type="text"
                     autocomplete="postal-code"
                     placeholder="Código postal" :required="true"></ion-input>
        </ion-item>
      </ion-list>
      <ion-button type="submit" class="ion-margin-top ion-no-margin" expand="full" color="secondary">Confirmar
      </ion-button>
    </form>
  </ion-content>
</template>

<script>
import {
  IonButton,
  IonList,
  IonListHeader,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonInput, IonToolbar, IonHeader, IonButtons, IonIcon,
  modalController
} from '@ionic/vue';
import { defineComponent } from 'vue'
import { mapGetters, mapMutations } from "vuex";
import { accountStates } from "@/config/StaticData";

export default defineComponent({
  name: 'AddressModal',
  components: {
    IonList,
    IonListHeader,
    IonTitle,
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonButton,
    IonIcon
  },
  computed: {
    ...mapGetters(['deliveryShippingData']),
  },
  methods: {
    ...mapMutations(['setDeliveryAddress']),
    hideModal: function () {
      modalController.dismiss()
    },
    submitAddressForm: function () {
      this.setDeliveryAddress({
        ...this.deliveryShippingData,
        country: "ES",
        state: accountStates.find((state) => state.postcode === this.deliveryShippingData.postcode.substring(0, 2)).code
      });
      modalController.dismiss();
    }
  },
})
</script>

<style lang="scss">
.address-modal {
  .ion-page {
    .inner-content {
      padding: 10px 25px 25px 25px;
    }

    .select-text {
      display: none;
    }
  }
}
</style>
