
import {
  IonLabel,
  IonList,
  IonItem,
  IonNote,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  modalController
} from '@ionic/vue';
import { defineComponent } from "vue";
import CartProductModal from "@/components/Modals/CartProductModal.vue";
import cartModule from '@/store/modules/cart';

export default defineComponent({
  name: 'CartAddressComponent',
  components: {IonLabel, IonList, IonItem, IonNote, IonItemSliding, IonItemOptions, IonItemOption},
  methods: {
    isDefaultOption(option: string, selected: string) {
      return option === 'Tipo de carne' && selected === 'Carne de ternera' ||
        option === 'Salsa' && selected === 'Con mayonesa';
    },
    capitalizeCheckboxOptionList(list: string[]) {
      const filteredList = list.filter((o: any) => o.selected).map((o: any) => o.label)
      const joinedList = filteredList.join(', ').toLowerCase();
      return joinedList.charAt(0).toUpperCase() + joinedList.slice(1);
    },
    async openCartItemPropertiesModal(itemId: string, cartItem: any) {
      const modal = await modalController
        .create({
          component: CartProductModal,
          cssClass: 'adaptable-height-modal cart-product-modal',
          componentProps: {cartItem},
        })
      return modal.present();
    },
  },

  setup() {
    return {
      cartModule,
    };
  }
})
