
import {
  IonPage, IonContent, IonFooter, IonToolbar,
  IonButton, IonIcon, IonGrid, IonRow, IonHeader,
  IonButtons, IonTitle, IonSegment, IonSegmentButton,
  IonLabel, IonNote, IonList, IonItem, modalController,
} from '@ionic/vue';

import {
  thumbsDownOutline, ellipsisVertical, storefrontOutline, rocketOutline,
  stopwatchOutline, timeOutline
} from 'ionicons/icons';

import { mapGetters, mapMutations } from "vuex";
import cartModule from '@/store/modules/cart';

import CartItemsComponent from "@/components/Cart/CartItemsComponent.vue";
import PaymentMethodModal from "@/components/Cart/Modals/PaymentMethodModal.vue";
import OrderNotesModal from "@/components/Cart/Modals/OrderNotesModal.vue";
import CouponModal from "@/components/Cart/Modals/CouponModal.vue";
import ChangeDeliveryTimeModal from "@/components/Cart/Modals/ChangeDeliveryTimeModal.vue";
import AddressModal from "@/components/Cart/Modals/AddressModal.vue";

import { StoreCartStateDeliveryType } from "@/interfaces/Store";
import { defineComponent } from "vue";

export default defineComponent({
  name: 'Cart',
  components: {
    CartItemsComponent,
    IonPage,
    IonContent,
    IonFooter,
    IonToolbar,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonHeader,
    IonButtons,
    IonTitle,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonNote,
    IonList,
    IonItem,
  },
  methods: {
    ...mapMutations(['setDeliveryMethod']),
    async openPaymentMethodModal() {
      const modal = await modalController.create({
        component: PaymentMethodModal,
        cssClass: 'adaptable-height-modal payment-method-modal',
      });
      return modal.present();
    },
    async openOrderNotesModal() {
      const modal = await modalController.create({
        component: OrderNotesModal,
        cssClass: 'adaptable-height-modal order-notes-modal',
      });
      return modal.present();
    },
    async openCouponCodeModal() {
      const modal = await modalController.create({
        component: CouponModal,
        cssClass: 'adaptable-height-modal coupon-modal',
        componentProps: {
          couponCode: this.couponData.code,
        }
      });
      return modal.present();
    },
    async openChangeDeliveryTimeModal() {
      const modal = await modalController.create({
        component: ChangeDeliveryTimeModal,
        cssClass: 'adaptable-height-modal change-delivery-time-modal',
        componentProps: {
          scheduledOptions: this.deliveryScheduledOptions,
        }
      });

      modal.onWillDismiss().then((modalData: { data?: { asap: boolean; scheduledTime: string } }) => {
        if (modalData.data) {
          const {asap, scheduledTime} = modalData.data;
          this.$emit('update', {asap, scheduledTime});
        }
      });

      return modal.present();
    },
    async openAddressModal() {
      const thisPageRef: any = this.$parent?.$parent;
      const presentingElement: HTMLElement = thisPageRef.$el;

      const modal = await modalController.create({
        swipeToClose: true,
        presentingElement: presentingElement,
        component: AddressModal,
        cssClass: 'address-modal',
      });

      return modal.present();
    },
    deliveryTimeItemLabelText(asap: boolean, scheduledTime?: StoreCartStateDeliveryType["schedule"]): string {
      const selectedOption = this.deliveryScheduledOptions.find((o: { value: string; label: string }) => o.value === scheduledTime);
      if (this.deliveryData.method === 'delivery') {
        return asap ? 'Entregar cuanto antes' : selectedOption ? `Entregar de ${selectedOption.label}` : 'Se ha producido un error';
      } else {
        return asap ? 'Recoger cuanto antes' : selectedOption ? `Recoger de ${selectedOption.label}` : 'Se ha producido un error';
      }
    },
  },
  computed: {
    ...mapGetters(['shippingPostCode', 'deliveryMethod', 'availableMethodTypes', 'customerNote', 'couponData', 'deliveryShippingData']),
    selectedDeliveryMethod: {
      get(): string {
        return this.deliveryMethod;
      },
      set(value: string) {
        this.setDeliveryMethod(value);
      }
    }
  },
  watch: {
    // If shippingPostCode getter value changes, refresh the availableMethodTypes
    // and if the current selected segment is missing, change the selected option to
    // the first available method found and emit an update event back to the parent.
    shippingPostCode: function () {
      if (!this.availableMethodTypes.includes(this.selectedDeliveryMethod)) {
        this.selectedDeliveryMethod = this.availableMethodTypes.length ? this.availableMethodTypes[0] : "";
      }
    }
  },
  setup() {
    function logOrder() {
      console.log(JSON.stringify(cartModule.orderObject, undefined, 4));
    }

    const deliveryScheduledOptions = [
      {value: "14:00", label: "14:00 - 14:20"},
      {value: "14:20", label: "14:20 - 14:40"},
      {value: "14:40", label: "14:40 - 15:00"},
      {value: "15:00", label: "15:00 - 15:20"},
      {value: "15:20", label: "15:20 - 15:40"},
      {value: "15:40", label: "15:40 - 16:00"},
      {value: "16:00", label: "16:00 - 16:20"},
      {value: "16:20", label: "16:20 - 16:40"},
    ];

    return {
      logOrder,
      cartModule,
      deliveryData: cartModule.deliveryData,
      deliveryScheduledOptions,
      stopwatchOutline,
      timeOutline,
      thumbsDownOutline,
      ellipsisVertical,
      storefrontOutline,
      rocketOutline,
    }
  }
})
