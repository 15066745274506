<template>
  <div class="inner-content ion-text-center text-dark">
    <ion-list lines="full">
      <ion-item class="ion-no-padding">
        <ion-textarea debounce="1000" v-model="customerNote" maxlength="200" rows="4"
                      placeholder="Notas del pedido"></ion-textarea>
      </ion-item>
    </ion-list>
    <ion-button class="ion-margin-top ion-no-margin" expand="full" color="secondary" @click="dismissModal()">Confirmar
    </ion-button>
  </div>
</template>

<script>
import {
  IonButton,
  IonList,
  IonItem,
  IonTextarea,
  modalController
} from '@ionic/vue';
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";

export default defineComponent({
  name: 'OrderNotes',
  components: {IonButton, IonList, IonItem, IonTextarea},
  methods: {
    ...mapMutations(['setCustomerNote']),
  },
  computed: {
    ...mapState({note: state => state.cart.note}),
    customerNote: {
      get() {
        return this.note;
      },
      set(value) {
        this.setCustomerNote(value);
      }
    }
  },
  setup() {
    function dismissModal() {
      modalController.dismiss();
    }

    return {
      dismissModal,
    };
  }
})
</script>

<style lang="scss">
.order-notes-modal {
  .ion-page {
    .inner-content {
      padding: 10px 25px 25px 25px;
    }

    .select-text {
      display: none;
    }
  }
}
</style>
