<template>
  <div class="inner-content ion-text-center text-dark">
    <ion-list lines="full">
      <ion-radio-group v-model="deliveryAsap">
        <ion-item class="ion-no-padding">
          <ion-icon :icon="stopwatchOutline" slot="start"></ion-icon>
          <ion-label>Lo antes posible</ion-label>
          <ion-radio :value="true" slot="end" mode="md"></ion-radio>
        </ion-item>
        <ion-item class="ion-no-padding">
          <ion-icon :icon="timeOutline" slot="start"></ion-icon>
          <ion-label>Programar para más tarde</ion-label>
          <ion-radio :value="false" slot="end" mode="md"></ion-radio>
        </ion-item>
      </ion-radio-group>
      <ion-item class="ion-no-padding" :disabled="deliveryAsap">
        <ion-icon slot="start"></ion-icon>
        <ion-select v-model="deliverySchedule"
                    cancel-text="Cancelar"
                    ok-text="OK"
                    :interface-options="{header: delivery.method === 'delivery' ? 'Horario de entrega' : 'Horario de recogida'}"
                    :selected-text="deliverySchedule ? delivery.method === 'delivery' ? 'Entregar a las ' + scheduledOptions.find((o) => o.value === deliverySchedule).label : 'Recoger a las ' + scheduledOptions.find((o) => o.value === deliverySchedule).label : 'Selecciona una opción'"
                    style="--padding-end: 17px; width: 100%">
          <ion-select-option
            v-for="(option, index) in scheduledOptions"
            :value="option.value"
            :key="index">
            {{ option.label }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
    <ion-button class="ion-margin-top ion-no-margin" expand="full" color="secondary"
                @click="dismissModal()">Confirmar
    </ion-button>
  </div>
</template>

<script>
import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonRadio,
  IonRadioGroup,
  modalController
} from '@ionic/vue';
import { stopwatchOutline, timeOutline } from 'ionicons/icons'
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";

export default defineComponent({
  name: 'ChangeDeliveryTimeModal',
  components: {IonButton, IonList, IonItem, IonLabel, IonIcon, IonSelect, IonSelectOption, IonRadio, IonRadioGroup},
  props: ['scheduledOptions'],
  computed: {
    ...mapState({delivery: state => state.cart.delivery}),
    deliveryAsap: {
      get() {
        return this.delivery.asap;
      },
      set(value) {
        this.setDeliveryAsap(value);
      }
    },
    deliverySchedule: {
      get() {
        return this.delivery.schedule;
      },
      set(value) {
        this.setDeliverySchedule(value);
      }
    }
  },
  methods: {
    ...mapMutations(['setDeliveryAsap', 'setDeliverySchedule']),
  },
  setup() {
    function dismissModal() {
      modalController.dismiss();
    }

    return {
      dismissModal,
      stopwatchOutline,
      timeOutline,
    };
  }
})
</script>

<style lang="scss">
.change-delivery-time-modal {
  .ion-page {
    .inner-content {
      padding: 10px 25px 25px 25px;
    }

    .select-text {
      display: none;
    }
  }
}
</style>
