<template>
  <div class="inner-content ion-text-center text-dark">
    <ion-list lines="full">
      <ion-item class="ion-no-padding" :class="validationErrors.length ? 'invalid-coupon' : undefined">
        <ion-label position="floating">Código del cupón</ion-label>
        <ion-input v-model="coupon" autocomplete="off" :clear-on-edit="true"
                   @ionChange="validationErrors = []"></ion-input>
        <ion-icon v-if="validationErrors.length" slot="end" :icon="warningOutline"/>
      </ion-item>
      <!-- TODO: [UI] Should render a popover to prevent moving the input box when errors are shown? (rdelcampo) -->
      <small class="invalid-coupon" v-for="(error, index) in validationErrors" :key="index">{{ error }}&nbsp;</small>
    </ion-list>
    <ion-button class="ion-margin-top ion-no-margin" expand="full" color="secondary" @click="validateCouponCode()"
                :disabled="validatingCoupon">
      <ion-spinner v-if="validatingCoupon"/>
      <template v-else>Confirmar</template>
    </ion-button>
  </div>
</template>

<script>
import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput, IonSpinner, IonIcon,
  modalController
} from '@ionic/vue';
import { defineComponent, ref } from "vue";
import { warningOutline } from "ionicons/icons";
import cartModule from '@/store/modules/cart';

export default defineComponent({
  name: 'CouponModal',
  components: {IonButton, IonList, IonItem, IonLabel, IonInput, IonSpinner, IonIcon},
  props: ['couponCode'],
  setup(props) {
    const coupon = ref(props.couponCode);
    const validatingCoupon = ref(false);
    const validationErrors = ref([]);

    function validateCouponCode() {
      if (coupon.value) {
        validatingCoupon.value = true;
        cartModule.addCoupon(coupon.value).then(() => {
          modalController.dismiss();
        }).catch((err) => {
          validationErrors.value = err.errors
        }).finally(() => {
          validatingCoupon.value = false;
        })
      } else {
        cartModule.clearCoupon();
        modalController.dismiss();
      }
    }

    return {
      coupon,
      validatingCoupon,
      validationErrors,
      warningOutline,
      validateCouponCode,
    };
  }
})
</script>

<style lang="scss">
.coupon-modal {
  .ion-page {
    .inner-content {
      padding: 10px 25px 25px 25px;

      .invalid-coupon {
        --color: var(--ion-color-danger);
        color: var(--ion-color-danger);
        --border-color: var(--ion-color-danger);
      }
    }

    .select-text {
      display: none;
    }
  }
}
</style>
