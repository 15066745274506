<template>
  <div class="inner-content ion-text-center text-dark">
    <ion-list lines="full">
      <ion-radio-group v-model="paymentMethod">
        <ion-item class="ion-no-padding" v-for="(method, methodId) in paymentMethods" :key="methodId">
          <ion-radio :value="methodId" slot="start" mode="md"></ion-radio>
          <ion-label>{{ method.name }}</ion-label>
          <ion-icon v-if="method.icon" slot="end" :icon="method.icon"></ion-icon>
        </ion-item>
      </ion-radio-group>
    </ion-list>
    <ion-button class="ion-margin-top ion-no-margin" expand="full" color="secondary" @click="dismissModal()">Confirmar
    </ion-button>
  </div>
</template>

<script>
import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonRadio,
  IonRadioGroup,
  modalController
} from '@ionic/vue';
import { defineComponent } from "vue";
import { paymentMethods } from "@/config/StaticStrings";
import { mapMutations, mapState } from "vuex";

export default defineComponent({
  name: 'PaymentMethodModal',
  components: {IonButton, IonList, IonItem, IonLabel, IonIcon, IonRadio, IonRadioGroup},
  methods: {
    ...mapMutations(['setPaymentMethod'])
  },
  computed: {
    ...mapState({method: state => state.cart.paymentMethod}),
    paymentMethod: {
      get() {
        return this.method;
      },
      set(value) {
        this.setPaymentMethod(value);
      }
    }
  },
  setup() {
    function dismissModal() {
      modalController.dismiss();
    }

    return {
      paymentMethods,
      dismissModal,
    };
  }
})
</script>

<style lang="scss">
.payment-method-modal {
  .ion-page {
    .inner-content {
      padding: 10px 25px 25px 25px;
    }

    .select-text {
      display: none;
    }
  }
}
</style>
